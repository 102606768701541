import { createGlobalStyle } from "styled-components"
import DonaRegularWoff from "../fonts/Dona-Regular.woff"
import DonaRegularWoff2 from "../fonts/Dona-Regular.woff2"
import DonaRegularEot from "../fonts/Dona-Regular.eot"
import DonaMediumWoff from "../fonts/Dona-Medium.woff"
import DonaMediumWoff2 from "../fonts/Dona-Medium.woff2"
import DonaMediumEot from "../fonts/Dona-Medium.eot"
import DonaBoldWoff from "../fonts/Dona-Bold.woff"
import DonaBoldWoff2 from "../fonts/Dona-Bold.woff2"
import DonaBoldEot from "../fonts/Dona-Bold.eot"
import DonaAltRegularWoff from "../fonts/DonaAlt-Regular.woff"
import DonaAltRegularWoff2 from "../fonts/DonaAlt-Regular.woff2"
import DonaAltRegularEot from "../fonts/DonaAlt-Regular.eot"
import DonaAltMediumWoff from "../fonts/DonaAlt-Medium.woff"
import DonaAltMediumWoff2 from "../fonts/DonaAlt-Medium.woff2"
import DonaAltMediumEot from "../fonts/DonaAlt-Medium.eot"
import DonaAltBoldWoff from "../fonts/DonaAlt-Bold.woff"
import DonaAltBoldWoff2 from "../fonts/DonaAlt-Bold.woff2"
import DonaAltBoldEot from "../fonts/DonaAlt-Bold.eot"

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'DonaRegular';
    src: local('DonaRegular'), local('DonaRegular'),
    url(${DonaRegularWoff}) format('woff'),
    url(${DonaRegularWoff2}) format('woff2'),
    url(${DonaRegularEot}) format('eot');
    font-weight: 500;
  }

  @font-face {
    font-family: 'DonaMedium';
    src: local('DonaMedium'), local('DonaMedium'),
    url(${DonaMediumWoff}) format('woff'),
    url(${DonaMediumWoff2}) format('woff2'),
    url(${DonaMediumEot}) format('eot');
    font-weight: 600;
  }
  @font-face {
    font-family: 'DonaBold';
    src: local('DonaBold'), local('DonaBold'),
    url(${DonaBoldWoff}) format('woff'),
    url(${DonaBoldWoff2}) format('woff2'),
    url(${DonaBoldEot}) format('eot');
    font-weight: 700;
  }

  @font-face {
    font-family: 'DonaAltRegular';
    src: local('DonaAltRegular'), local('DonaAltRegular'),
    url(${DonaAltRegularWoff}) format('woff'),
    url(${DonaAltRegularWoff2}) format('woff2'),
    url(${DonaAltRegularEot}) format('eot');
    font-weight: 500;
  }

  @font-face {
    font-family: 'DonaAltMedium';
    src: local('DonaAltMedium'), local('DonaAltMedium'),
    url(${DonaAltMediumWoff}) format('woff'),
    url(${DonaAltMediumWoff2}) format('woff2'),
    url(${DonaAltMediumEot}) format('eot');
    font-weight: 600;
  }
  @font-face {
    font-family: 'DonaAltBold';
    src: local('DonaAltBold'), local('DonaAltBold'),
    url(${DonaAltBoldWoff}) format('woff'),
    url(${DonaAltBoldWoff2}) format('woff2'),
    url(${DonaAltBoldEot}) format('eot');
    font-weight: 700;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    height: 100%;
    text-rendering: optimizeLegibility;
    background: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: #333;
    font-size: 1rem;
  }
`

export default GlobalStyle
