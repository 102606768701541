const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileXS: "578px",
  tablet: "768px",
  laptopXS: "1024px",
  laptopS: "1200px",
  laptop: "1280px",
  laptopM: "1330px",
  laptopN: "1400px",
  laptopL: "1440px",
  laptopR: "1443px",
  desktopXS: "1900px",
  desktop: "1920px",
  desktopL: "2560px",
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  mobileXS: `(max-width: ${size.mobileXS})`,
  tablet: `(min-width: ${size.tablet})`,
  laptopXS: `(min-width: ${size.laptopXS})`,
  laptopS: `(min-width: ${size.laptopS})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopM: `(min-width: ${size.laptopM})`,
  laptopN: `(min-width: ${size.laptopN})`,
  laptopL: `(min-width: ${size.laptopL})`,
  laptopR: `(min-width: ${size.laptopR})`,
  desktopXS: `(min-width: ${size.desktopXS})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}
