import React from "react"
import { Link } from "gatsby"

import logoMerce from "../../images/logo-merce-primary.svg"
import * as Styles from "./styles"

const Footer: React.FC = () => {
  const year = new Date().getFullYear()

  return (
    <Styles.Footer>
      <Styles.FooterMedia>
        <Styles.FooterLogo>
          <Link to="/">
            <img src={logoMerce} alt="merce do bairro" />
          </Link>
        </Styles.FooterLogo>
        <Styles.FooterMenu>
          <Styles.FooterMenuItem>
            <a
              href="https://cliente.varejointegrado.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver produtos
            </a>
          </Styles.FooterMenuItem>
          <Styles.FooterMenuItem>
            <a
              href="https://mercedobairro.gupy.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trabalhe conosco
            </a>
          </Styles.FooterMenuItem>
          <Styles.FooterMenuItem>
            <Link to="/politica-de-privacidade">Política de Privacidade</Link>
          </Styles.FooterMenuItem>
          <Styles.FooterMenuItem>
            <Link to="/termos-de-uso">Termos de Uso</Link>
          </Styles.FooterMenuItem>
        </Styles.FooterMenu>
        <Styles.FooterContact>
          <p>Vamos bater um papo?</p>
          <span>meajuda@mercedobairro.com.br</span>
          <span>(11) 93447-0146</span>
        </Styles.FooterContact>
      </Styles.FooterMedia>
      <Styles.FooterReserved>
        <p>© {year} Varejo Integrado Servicos e Tecnologia Ltda.</p>
      </Styles.FooterReserved>
    </Styles.Footer>
  )
}

export default Footer
