import styled from 'styled-components'

import { device } from '../../styles/devices'

export const Header = styled.header`
  background-color: #db0047;
  height: 88px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media all and ${device.laptopXS} {
    position: fixed;
  }
`

export const HeaderContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding: 0 16px;

  img {
    width: 100%;
  }
`

export const Menu = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
`

export const MenuItem = styled.li`
  color: #fff;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  font-weight: 400;
  line-height: 2.5;
  border: 1px solid #fff;
  border-radius: 30px;
  height: 40px;
  width: 149px;
  margin: 0 6px;

  label {
    display: none;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  &:hover {
    color: #fff757;
    border-color: #fff757;

    a {
      color: #fff757;
    }
  }

  &:last-child {
    display: none;
  }

  @media all and ${device.laptopXS} {
    width: 176px;
    margin: 0 16px;

    label {
      display: block;
      border: none;
      background-color: #fff757;
      cursor: pointer;
      font-weight: 600;
      color: #333333;
      border-radius: 30px;

      &:hover {
        background-color: #fffbba;
      }
    }

    &:last-child {
      border: none;
      display: block;
    }
  }
`
