import styled from 'styled-components'

import { device } from '../../styles/devices'

export const Footer = styled.footer`
  background-color: #fafafa;
  color: #666666;
`

export const FooterMedia = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  @media all and ${device.laptopXS} {
    display: flex;
    justify-content: space-between;
  }
`

export const FooterLogo = styled.div`
  display: none;

  @media all and ${device.laptopXS} {
    display: block;
    padding: 48px 0;
  }
`

export const FooterMenu = styled.ul`
  list-style: none;
  text-align: center;
  padding: 8px 0;

  li {
    margin: 16px 0;
  }

  @media all and ${device.tablet} {
    padding: 0;
    li {
      & + li {
        margin: 0;
        margin-left: 32px;
      }
    }
  }
  @media all and ${device.laptopXS} {
    display: flex;
  }
`

export const FooterMenuItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #db0047;
  font-weight: 600;

  a {
    text-decoration: none;
    color: #db0047;

    &:hover {
      color: #e54161;
    }
  }

  p {
    cursor: pointer;
  }

  @media all and ${device.laptop} {
    margin: 0 32px;
  }
`

export const FooterContact = styled.div`
  text-align: center;
  padding: 2rem;

  p {
    font-weight: 600;
    color: #333333;
  }
  span {
    display: block;
  }
  @media all and ${device.laptopXS} {
    text-align: right;
    padding: 48px 0;
  }
`

export const FooterReserved = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  color: #666666;
  height: 56px;
  padding: 16px;
  border-top: 1px solid #e6e6e6;

  p {
    font-size: 0.9rem;
    text-align: center;
  }

  @media all and ${device.laptopXS} {
    p {
      font-size: 1rem;
      text-align: center;
    }
  }
`
