import React from 'react'

import { Link } from 'gatsby'

import Brand from '../../images/logo-merce.svg'
import * as Styles from './styles'

const goToSection = () => {
  document.getElementById('quero-fazer-parte').scrollIntoView({
    behavior: 'smooth',
  })
}

const Header: React.FC = () => (
  <Styles.Header>
    <Styles.HeaderContent>
      <Link to="/">
        <img src={Brand} alt="merce do bairro" />
      </Link>
      <Styles.Menu>
        <Styles.MenuItem
          onClick={() => {
            window.location.href = 'https://cliente.mercedobairro.com/'
          }}
        >
          <a href="https://cliente.mercedobairro.com/" target="_blank" rel="noopener noreferrer">
            Ver produtos
          </a>
        </Styles.MenuItem>
        <Styles.MenuItem onClick={goToSection}>
          <label>Quero fazer parte</label>
        </Styles.MenuItem>
      </Styles.Menu>
    </Styles.HeaderContent>
  </Styles.Header>
)

export default Header
